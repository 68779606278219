<template>
	<div id="app">
		<router-view />

		<x-scale-box style="pointer-events: none">
			<!-- HZ视频 -->
			<x-video v-if="showvideoHZType" style="pointer-events: auto" :video-src="videoData"
				@on-close="showvideoHZType = false"></x-video>

			<!--        <x-video-list v-if="showVideoList"  :video-list="videoListData" @on-close="showvideoHZType = false"></x-video-list>-->
			<x-video-list-play v-if="showVideoList" :video-list="videoListData"
				@on-close="showVideoList = false"></x-video-list-play>

			<x-device-shang-qing :title-name="shangQingTitle" v-if="showShangQingType" :comp-data="compData"
				@on-close="showShangQingType = false"></x-device-shang-qing>

			<xjiebei :title-name="jiebeiTitle" v-if="showjiebeiType" :comp-data="compData"
				@on-close="showjiebeiType = false"></xjiebei>
			<xiaojiebei ref="dialog" v-if="xiaojiebeiType" :title-name="jiebeiTitle" :comp-data="compData"
				@on-point="xiaojiebeiType = false">
			</xiaojiebei>

			<!-- 渗压弹窗 -->
			<x-device-osmotic-pressure-dialog style="pointer-events: auto" v-if="showShenLiuDialogType"
				@on-close="showShenLiuDialogType = false" :comp-data="compData"></x-device-osmotic-pressure-dialog>
			<!-- 位移弹窗 -->
			<x-device-displacement-dialog style="pointer-events: auto" v-if="showWeiYiDialogType"
				@on-close="showWeiYiDialogType = false" :comp-data="compData"></x-device-displacement-dialog>

			<!-- 泵组弹窗 -->
			<x-device-beng-dialog style="pointer-events: auto" v-if="showBengDialogType"
				@on-close="showBengDialogType = false"></x-device-beng-dialog>

			<!-- 全景图 -->
			<x-overall-view style="pointer-events: auto" v-if="showOverallViewDialogType"
				@on-close="showOverallViewDialogType = false"></x-overall-view>

			<!-- 水质弹窗 -->
			<x-device-water-quality-dialog style="pointer-events: auto" v-if="showWaterQualityType"
				:device-id="waterQualityDeviceId"
				@on-close="showWaterQualityType = false"></x-device-water-quality-dialog>

			<!-- 运行记录弹窗 -->
			<x-operation-record style="pointer-events: auto" v-if="showOperationRecordType"
				@on-close="showOperationRecordType = false"></x-operation-record>

			<!-- 流量弹窗 -->
			<x-device-liu-liang style="pointer-events: auto" v-if="showLiuLiangType"
				@on-close="showLiuLiangType = false"></x-device-liu-liang>

			<!-- 潮汐弹窗 -->
			<x-device-chao-xi style="pointer-events: auto" v-if="showChaoXiDialogType"
				@on-close="showChaoXiDialogType = false"></x-device-chao-xi>

			<!-- 泥沙弹窗 -->
			<x-device-ni-sha style="pointer-events: auto" v-if="showNiShaDialogType"
				@on-close="showNiShaDialogType = false" :comp-data="compNiShaData"></x-device-ni-sha>

			<!-- 水位弹窗 -->
			<x-device-shui-wei style="pointer-events: auto" v-if="showShuiWeiDialogType"
				@on-close="showShuiWeiDialogType = false" :comp-data="compShuiWeiData"></x-device-shui-wei>

			<!-- 声纹弹窗 -->
			<x-device-sheng-wen style="pointer-events: auto" v-if="showShengWenDialogType"
				@on-close="showShengWenDialogType = false"></x-device-sheng-wen>

			<!-- 烟感弹窗 -->
			<xx-yg-video v-if="showYangGanDialogType" style="pointer-events: auto" :video-src="videoYGHZClickDeviceId"
				@on-close="showYangGanDialogType = false"></xx-yg-video>

			<!-- 永镇水库简介 -->
			<x-yzskjj style="pointer-events: auto" v-if="showYzshJJDialogType"
				@on-close="showYzshJJDialogType = false"></x-yzskjj>

			<!-- 电流弹窗 -->
			<x-device-dian-liu-lu-zhuang v-if="showDianLiuDialogType" @on-close="showDianLiuDialogType = false"
				:comp-data="compDianLiuData"></x-device-dian-liu-lu-zhuang>

			<x-device-pei-dian-gui style="pointer-events: auto" v-if="showPdgDialogType"
				@on-close="showPdgDialogType = false"></x-device-pei-dian-gui>

			<x-beng-fang-shu-ju-mian-ban v-if="shuJuMianBanBengFangType" @on-close="shuJuMianBanBengFangType=false"
				style="pointer-events: auto"></x-beng-fang-shu-ju-mian-ban>
			<x-shu-ju-mian-ban_-beng-qian v-if="shuJuMianBanBengQianType" @on-close="shuJuMianBanBengQianType=false"
				style="pointer-events: auto"></x-shu-ju-mian-ban_-beng-qian>
			<x-popup-comp v-if="showPopupType" :menu-id="showPopupMenuId" @on-close="showPopupType=false"
				style="pointer-events: auto"></x-popup-comp>
		</x-scale-box>
	</div>
</template>
<script>
	import {
		getJzjcxx, //界桩
		getBillboard, //公示牌
	} from "@/api/station/jiezhuang";
	import xjiebei from "@/components/x-station/jiebei.vue";
	import xiaojiebei from "@/components/x-station/xiaojiebei.vue";
	import XVideo from "@/components/x-video/index.vue";
	import XDeviceOsmoticPressureDialog from "@/components/x-device-osmoticPressure-dialog/index.vue";
	import XDeviceDisplacementDialog from "@/components/x-device-displacement-dialog/index.vue";
	import XDeviceBengDialog from "@/components/x-device-Beng/index.vue";
	import XOverallView from "@/components/x-overallView/index.vue";
	import XDeviceWaterQualityDialog from "@/components/x-device-waterQuality-dialog/index.vue";
	import XOperationRecord from "@/components/x-operation-record/index.vue";
	import XDeviceLiuLiang from "@/components/x-device-LiuLiang/index.vue";
	import XDeviceChaoXi from "@/components/x-device-ChaoXi/index.vue";
	import XDeviceShangQing from "@/components/x-device-ShangQing/index.vue";
	import XDeviceNiSha from "@/components/x-device-NiSha/index.vue";
	import XDeviceShengWen from "@/components/x-device-ShengWen/index.vue";
	import XxYgVideo from "@/components/x-yg-video/index.vue";
	import XYzskjj from "@/components/x-yzskjj/index.vue";
	import XDevicePeiDianGui from "@/components/x-device-PeiDianGui/index.vue";
	import XDeviceShuiWei from "@/components/x-device-ShuiWei/index.vue";
	import XDeviceDianLiuLuZhuang from "@/components/x-device-dianLiu-luZhuang/index.vue";
	import XVideoList from "@/views/video/components/x-video-list.vue";
	import XVideoListPlay from "@/components/x-video-list/index.vue";
	import XBengFangShuJuMianBan from "@/views/luzhuangguanqu_home/components/x-BengFang-ShuJuMianBan/index.vue";
	import XLaoLongQiaoShuJuMianBan from "@/views/luzhuangguanqu_home/components/x-LaoLongQiao-ShuJuMianBan/index.vue";
	import XShuJuMianBan_BengQian from "@/views/luzhuangguanqu_home/components/x-ShuJuMianBan_BengQian/index.vue";
	import XPopupComp from "@/views/luzhuangguanqu_home/components/x-popup-comp/index.vue";


	export default {
		components: {
			XPopupComp,
			XShuJuMianBan_BengQian,
			XLaoLongQiaoShuJuMianBan,
			XBengFangShuJuMianBan,
			XVideoListPlay,
			XVideoList,
			XDeviceDianLiuLuZhuang,
			XDeviceShuiWei,
			XDevicePeiDianGui,
			XYzskjj,
			XxYgVideo,
			XDeviceShengWen,
			XDeviceNiSha,
			XDeviceShangQing,
			XDeviceChaoXi,
			XDeviceLiuLiang,
			XOperationRecord,
			XDeviceWaterQualityDialog,
			XOverallView,
			XDeviceBengDialog,
			XDeviceDisplacementDialog,
			XDeviceOsmoticPressureDialog,
			XVideo,
			xjiebei,
			xiaojiebei,
		},
		data() {
			return {
				videoListData: [],
				showvideoHZType: false,
				showjiebeiType: false,
				xiaojiebeiType: false,
				showVideoList: false,
				showShangQingType: false,
				showWeiYiDialogType: false,
				showBengDialogType: false,
				showOverallViewDialogType: false,
				showWaterQualityType: false,
				showOperationRecordType: false,
				showShenLiuDialogType: false,
				showLiuLiangType: false,
				showChaoXiDialogType: false,
				showNiShaDialogType: false,
				showShuiWeiDialogType: false,
				showShengWenDialogType: false,
				showYangGanDialogType: false,
				showDianLiuDialogType: false,
				shuJuMianBanBengFangType: false,
				shuJuMianBanLaoLongQiaoType: false,
				shuJuMianBanBengQianType: false,
				showPopupMenuId: '',
				showPopupType: false,
				showYzshJJDialogType: false,
				showPdgDialogType: false,
				videoData: {},
				videoYGHZClickDeviceId: '',
				compData: {},
				compNiShaData: {},
				compShuiWeiData: {},
				compDianLiuData: {},
				shangQingTitle: '',
				shenYaDevice: {},
				weiYiDeviceId: '',
				waterQualityDeviceId: '',
				jiebeiTitle: '',
			}
		},
		created() {
			window.videoClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.videoData = {
					serialNumber: serialNumber,
					bindingId: deviceId,
					categoryId: categoryId,
					name: deviceName
				};
				this.showvideoHZType = true;
			}

			// 多合一摄像头查看
			window.videoListClick = (deviceId) => {
				let deviceIdArr = deviceId.toString().split(',')
				let videoListData = [];
				for (let i = 0; i < deviceIdArr.length; i++) {
					videoListData.push({
						bindingId: parseFloat(deviceIdArr[i]),
						name: "摄像头" + (i + 1)
					})
				}
				this.videoListData = videoListData;
				this.showVideoList = true;
			}

			// 视频关闭
			window.videoListClick_close = (deviceId) => {
				this.showVideoList = false;
			}

			window.shangQingClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.shangQingTitle = deviceName || '实时数据';
				this.compData = {
					// bindingId:deviceId
					serialNumber: serialNumber,
					bindingId: deviceId,
					categoryId: categoryId,
					name: deviceName
				};
				this.showShangQingType = true;
			}

			window.LiuLiangClick = (serialNumber, deviceId, categoryId, deviceName) => {
				// this.compDataName = '流量监测'
				this.shangQingTitle = deviceName;
				this.compData = {
					// serialNumber:deviceId
					serialNumber: serialNumber,
					bindingId: deviceId,
					categoryId: categoryId,
					name: deviceName
				};
				this.showShangQingType = true;
			}

			window.jiebeiClick = (deviceitem) => {
				console.log(deviceitem)
				if (deviceitem.bindingId != "" && deviceitem.bindingId != null) { //未绑定监控设备的不弹窗
					this.jiebeiTitle = deviceitem.name;
					this.compData = deviceitem;


					if (this.compData.bindingType == '/elements/jzjcxx/list?pageNum=1&pageSize=99999') {
						console.log("打开界碑弹窗:", this.compData.bindingId)
						getJzjcxx(this.compData.bindingId).then(response => {
							if (response.data.iamge != null && response.data.iamge != '') {
								this.showjiebeiType = true;
							} else {
								this.xiaojiebeiType = true;
							}
						});
					} else if (this.compData.bindingType == '/elements/billboard/list?pageNum=1&pageSize=99999') {
						console.log("打开公示牌弹窗:", this.compData.bindingId)
						this.showjiebeiType = true;

					} else if (this.compData.bindingType == '/water/boundarypoint/list?pageNum=1&pageSize=999999') {
						console.log("打开界址点弹窗:", this.compData.bindingId)
						this.xiaojiebeiType = true;

					} else if (this.compData.bindingType == '/bzaq/qxdwjl/list?pageNum=1&pageSize=99999') {
						console.log("打开抢险队伍弹窗:", this.compData.bindingId)
						this.xiaojiebeiType = true;
					} else if (this.compData.bindingType == '/bzaq/wzck/list?pageNum=1&pageSize=999999') {
						console.log("打开物资仓库弹窗:", this.compData.bindingId)
						this.xiaojiebeiType = true;
					}
				}
			}
			// 水位
			window.ShuiWeiClick = (serialNumber, deviceId, categoryId, deviceName = "实时数据") => {
				this.shangQingTitle = deviceName;
				this.compData = {
					// bindingId:deviceId
					serialNumber: serialNumber,
					bindingId: deviceId,
					categoryId: categoryId,
					name: deviceName
				};
				this.showShangQingType = true;
			}

			// 水位关闭
			window.ShuiWeiClick_close = () => {
				this.showShangQingType = false;
			}

			window.QiXiangClick = (serialNumber, deviceId, categoryId, deviceName) => {
				console.log("+++++++++++1++++++")
				// this.compDataName = '气象监测'
				this.shangQingTitle = deviceName;
				this.compData = {
					// serialNumber:deviceId
					serialNumber: serialNumber,
					bindingId: deviceId,
					categoryId: categoryId,
					name: deviceName
				};
				this.showShangQingType = true;
			}

			// 渗压
			window.shenYaClick = (deviceId, type) => {
				this.compData = {
					// serialNumber:deviceId
					serialNumber: '',
					bindingId: deviceId.toString(),
					categoryId: '',
					name: '渗压监测'
				};
				this.showShenLiuDialogType = true;
			}
			// 渗压弹窗关闭
			window.shenYaClick_close = () => {
				this.showShenLiuDialogType = false;
			}

			// 位移
			window.weiYiClick = (deviceId, type) => {
				// var arr = {"310":"1166"};
				// console.log(arr[deviceId])
				this.compData = {
					// serialNumber:deviceId
					serialNumber: '',
					bindingId: deviceId.toString(),
					// bindingId:arr[deviceId],
					categoryId: '',
					name: '位移监测'
				};
				this.showWeiYiDialogType = true;
			}

			window.weiYiClick_close = (deviceId, type) => {
				this.showWeiYiDialogType = false;
			}
			/*
    // 渗压
    window.shenYaClick = (serialNumber,deviceId,categoryId,deviceName) => {
			this.compData = {
			  // serialNumber:deviceId
				  serialNumber:serialNumber,
				  bindingId:deviceId,
					categoryId:categoryId,
					name:deviceName
			};
      this.showShenLiuDialogType = true;
    }

    // 位移
    window.weiYiClick = (serialNumber,deviceId,categoryId,deviceName) => {
      this.compData = {
        // serialNumber:deviceId
      	  serialNumber:serialNumber,
      	  bindingId:deviceId,
      		categoryId:categoryId,
      		name:deviceName
      };
      this.showWeiYiDialogType = true;
    }
 */
			/*    // 流量
			    window.liuLiangClick = (serialNumber,deviceId,categoryId,deviceName) => {
			      this.showLiuLiangType = true;
			    } */

			// 泵点击
			window.bengClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.showBengDialogType = true;
			}
			// 泵点击
			window.bengClick_close = () => {
				this.showBengDialogType = false;
			}

			// 全景图点击
			window.quanJingClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.showOverallViewDialogType = true;
			}

			// 运行监测
			window.yunXingClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.showOperationRecordType = true;
			}

			// 水质监测
			window.shuiZhiClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.waterQualityDeviceId = deviceId;
				this.showWaterQualityType = true;
			}

			window.shuiZhiClick_close = () => {
				this.showWaterQualityType = false;
			}

			// 潮汐监测
			window.chaoXiClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.showChaoXiDialogType = true;
			}

			// 泥沙监测
			window.niShaClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.compNiShaData = {
					// bindingId:deviceId || 'D1114876'
					serialNumber: serialNumber,
					bindingId: deviceId || 'D1114876',
					categoryId: categoryId,
					name: deviceName
				};
				this.showNiShaDialogType = true;
			}

			// 声纹监测
			window.shengWenClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.showShengWenDialogType = true;
			}


			// 烟感监测
			window.yanGanClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.videoYGHZClickDeviceId = {
					// serialNumber:deviceId
					serialNumber: serialNumber,
					bindingId: deviceId || 'D1114876',
					categoryId: categoryId,
				};
				this.showYangGanDialogType = true;
			}

			// 永镇水库简介
			window.yzskjjClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.showYzshJJDialogType = true;
			}

			// 配电柜监测
			window.pdgClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.showPdgDialogType = true;
			}

			// 电流监测
			window.dianLiuClick = (serialNumber, deviceId, categoryId, deviceName) => {
				this.showDianLiuDialogType = false;
				this.$nextTick(() => {
					this.compDianLiuData = {
						// serialNumber:deviceId
						serialNumber: serialNumber,
						bindingId: deviceId,
						categoryId: categoryId,
						deviceName: deviceName,
					};
					this.showDianLiuDialogType = true;
				})
			}

			window.dianLiuClick_close = () => {
				this.showDianLiuDialogType = false;
			}

			window.shuJuMianBanBengFangClick = () => {
				this.shuJuMianBanBengFangType = true;
			}
			window.shuJuMianBanLaoLongQiaoClick = () => {
				this.shuJuMianBanLaoLongQiaoType = true;
			}
			window.shuJuMianBanengQianClick = () => {
				this.shuJuMianBanBengQianType = true;
			}
			window.showPopupType = (val) => {
				this.showPopupType = false;
				this.$nextTick(() => {
					this.showPopupType = true;
					this.showPopupMenuId = val;
				});
			}
		},
	}
</script>
<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		overflow: hidden;
	}

	body {
		margin: 0;
		overflow: hidden;
		cursor: url('~@/assets/common/cursor.png'), auto;
	}

	::-webkit-scrollbar-button {
		height: 0;
		width: 0;
		display: none
	}

	::-webkit-scrollbar-track {
		background: transparent
	}

	::-webkit-scrollbar-track,
	::-webkit-scrollbar-thumb {
		border: 0
	}

	::-webkit-scrollbar {
		height: 10px;
		width: 4px;
		background: transparent;
		border-radius: 5px
	}

	::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset -1px -1px 0 rgba(0, 0, 0, .07);
		background-color: #8EB7DE;
		min-height: 28px;
		border-radius: 4px;
		background-clip: padding-box
	}

	::-webkit-scrollbar-track,
	::-webkit-scrollbar-thumb {
		border: 0
	}

	::-webkit-scrollbar-thumb:hover {
		-webkit-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .25);
		background-color: rgba(0, 0, 0, .4)
	}

	::-webkit-scrollbar-thumb:active {
		-webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .35);
		background-color: rgba(0, 0, 0, .5)
	}
</style>