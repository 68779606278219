import request from '@/utils/request'

// 查询灌区基础信息列表
export function listIrrigatedArea(query) {
  return request({
    url: '/water/irrigatedArea/list',
    method: 'get',
    params: query
  })
}

// 查询灌区基础信息详细
export function getIrrigatedArea(id) {
  return request({
    url: '/water/irrigatedArea/' + id,
    method: 'get'
  })
}

// 新增灌区基础信息
export function addIrrigatedArea(data) {
  return request({
    url: '/water/irrigatedArea',
    method: 'post',
    data: data
  })
}

// 修改灌区基础信息
export function updateIrrigatedArea(data) {
  return request({
    url: '/water/irrigatedArea',
    method: 'put',
    data: data
  })
}

// 删除灌区基础信息
export function delIrrigatedArea(id) {
  return request({
    url: '/water/irrigatedArea/' + id,
    method: 'delete'
  })
}
