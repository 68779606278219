import request from '@/utils/request'

// 查询河道基础信息列表
export function listRivers(query) {
  return request({
    url: '/water/rivers/list',
    method: 'get',
    params: query
  })
}

// 查询河道基础信息详细
export function getRivers(id) {
  return request({
    url: '/water/rivers/' + id,
    method: 'get'
  })
}

// 新增河道基础信息
export function addRivers(data) {
  return request({
    url: '/water/rivers',
    method: 'post',
    data: data
  })
}

// 修改河道基础信息
export function updateRivers(data) {
  return request({
    url: '/water/rivers',
    method: 'put',
    data: data
  })
}

// 删除河道基础信息
export function delRivers(id) {
  return request({
    url: '/water/rivers/' + id,
    method: 'delete'
  })
}
