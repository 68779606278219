import request from '@/utils/request'

// 查询水库基础信息列表
export function listReservoir(query) {
  return request({
    url: '/water/reservoir/list',
    method: 'get',
    params: query
  })
}

// 查询水库基础信息详细
export function getReservoir(id) {
  return request({
    url: '/water/reservoir/' + id,
    method: 'get'
  })
}

// 新增水库基础信息
export function addReservoir(data) {
  return request({
    url: '/water/reservoir',
    method: 'post',
    data: data
  })
}

// 修改水库基础信息
export function updateReservoir(data) {
  return request({
    url: '/water/reservoir',
    method: 'put',
    data: data
  })
}

// 删除水库基础信息
export function delReservoir(id) {
  return request({
    url: '/water/reservoir/' + id,
    method: 'delete'
  })
}
