import request from '@/utils/request'

// 查询物资仓库列表
export function listWzck(query) {
  return request({
    url: '/bzaq/wzck/list',
    method: 'get',
    params: query
  })
}

// 查询物资仓库详细
export function getWzck(id) {
  return request({
    url: '/bzaq/wzck/' + id,
    method: 'get'
  })
}

// 新增物资仓库
export function addWzck(data) {
  return request({
    url: '/bzaq/wzck',
    method: 'post',
    data: data
  })
}

// 修改物资仓库
export function updateWzck(data) {
  return request({
    url: '/bzaq/wzck',
    method: 'put',
    data: data
  })
}

// 删除物资仓库
export function delWzck(id) {
  return request({
    url: '/bzaq/wzck/' + id,
    method: 'delete'
  })
}
